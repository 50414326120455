@font-face {
  font-family: "FuturaTMedium";
  src: url("../fonts/futura/Futura-T-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Touche";
  src: url("../fonts/touche/Touche-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Touche";
  src: url("../fonts/touche/Touche-Semibold.otf") format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "Touche";
  src: url("../fonts/touche/Touche-Bold.otf") format("opentype");
  font-weight: 900;
}
.urgent-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 5px;
  margin-bottom: 20px;
  background: linear-gradient(135deg, #ffcd00 0%, #ffe500 100%);
  box-sizing: border-box;
}

.urgent-notification--extended-margin {
  margin-bottom: 120px;
}

.urgent-notification__label {
  text-align: center;
}

.urgent-notification a {
  color: #24272d;
  font-weight: 700;
  text-decoration: underline;
}

@media only screen and (max-width: 500px) {
  .urgent-notification__label, .urgent-notification a {
    font-size: 14px;
  }
}
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1200px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s, box-shadow 0.3s;
  z-index: 999;
}

.navbar--fixed {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
}

.navbar__logo-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  color: #24272d;
  text-decoration: none;
}

.navbar__logo {
  max-width: 150px;
}

.navbar__title {
  user-select: none;
  pointer-events: none;
}

.navbar__link-group {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.navbar__link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  color: #24272d;
  text-decoration: none;
  height: 100%;
  padding: 0px 20px;
  cursor: pointer;
}

.navbar__link-label {
  position: relative;
  text-align: center;
  user-select: none;
  cursor: pointer;
  transition: opacity 0.3s;
}

.navbar__link-label:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 1px;
  background-color: #24272d;
  transition: width 0.3s;
}

.navbar__link-arrow-down {
  width: 12px;
  height: 12px;
  transition: opacity 0.3s, rotate 0.3s;
}

.navbar__dropdown-wrapper {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  visibility: hidden;
  transition: 0ms 300ms visibility;
}

.navbar__dropdown {
  position: relative;
  top: -50px;
  opacity: 0;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  transition: 300ms top, 300ms opacity;
}

.navbar__dropdown:before {
  content: "";
  position: absolute;
  top: -14px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  rotate: 45deg;
}

.navbar__dropdown-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 0px;
  color: #24272d;
  text-decoration: none;
}

.navbar__dropdown-link:first-child {
  padding-top: 30px;
}

.navbar__dropdown-link:last-child {
  padding-bottom: 30px;
}

.navbar__dropdown-link-label {
  position: relative;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
}

.navbar__dropdown-link-label:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 1px;
  background-color: #24272d;
  transition: width 0.3s;
}

.navbar__link-group:hover .navbar__link-label {
  opacity: 0.5;
}

.navbar__link-group:hover .navbar__link-arrow-down {
  opacity: 0.5;
}

.navbar__link:hover .navbar__link-label {
  opacity: 1 !important;
}

.navbar__link:hover .navbar__link-arrow-down {
  opacity: 1 !important;
}

.navbar__link:hover .navbar__link-label:after {
  width: 100%;
}

.navbar__link:hover .navbar__link-arrow-down {
  rotate: 180deg;
}

.navbar__link:hover .navbar__dropdown-wrapper {
  transition: 0ms 0ms visibility;
  visibility: visible;
}

.navbar__link:hover .navbar__dropdown {
  top: 0;
  opacity: 1;
}

.navbar__dropdown:hover .navbar__dropdown-link-label {
  opacity: 0.5;
}

.navbar__dropdown-link:hover .navbar__dropdown-link-label {
  opacity: 1 !important;
}

.navbar__dropdown-link:hover .navbar__dropdown-link-label:after {
  width: 100%;
}

@media only screen and (max-width: 1280px) {
  .navbar {
    display: none;
  }
}
.hex-background {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  z-index: 0;
}

.hex-background__icon {
  width: 100%;
  stroke: #6c6a5e;
  opacity: 0.2;
}

@media only screen and (max-width: 1280px) {
  .hex-background {
    display: none;
  }
}
.navbar-mobile {
  position: relative;
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0px 50px;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  transition: background-color 0.3s, box-shadow 0.3s;
  z-index: 999;
}

.navbar-mobile--fixed {
  position: fixed;
  margin: 0px;
  width: calc(100% - 40px);
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
}

.navbar-mobile__logo-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  color: #24272d;
  text-decoration: none;
}

.navbar-mobile__logo {
  max-width: 150px;
}

.navbar-mobile__title {
  user-select: none;
  pointer-events: none;
}

.navbar-mobile__hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.navbar-mobile__hamburger-bar {
  position: relative;
  width: 90%;
  height: 5px;
  background-color: #24272d;
  border-radius: 100px;
  transition: 0ms 150ms background-color;
}

.navbar-mobile__hamburger-bar:before, .navbar-mobile__hamburger-bar:after {
  content: "";
  position: absolute;
  top: -12px;
  width: 100%;
  height: 5px;
  border-radius: 100px;
  background-color: #24272d;
  opacity: 1 !important;
  transition: 150ms 150ms top, 150ms 0ms rotate;
}

.navbar-mobile__hamburger-bar:after {
  top: 12px;
}

.navbar-mobile__hamburger-bar--active {
  background-color: transparent;
}

.navbar-mobile__hamburger-bar--active:before {
  transition: 150ms 0ms top, 150ms 150ms rotate;
  top: 0px;
  rotate: 45deg;
}

.navbar-mobile__hamburger-bar--active:after {
  transition: 150ms 0ms top, 150ms 150ms rotate;
  top: 0px;
  rotate: -45deg;
}

.navbar-mobile__menu {
  position: absolute;
  top: 110px;
  left: 0;
  width: 100%;
  max-height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  overflow: scroll;
  transition: 0ms 0ms visibility, opacity 0.3s, top 0.3s, box-shadow 0.3s;
}

.navbar-mobile__menu::-webkit-scrollbar {
  display: none;
}

.navbar-mobile__menu {
  scrollbar-width: none;
}

.navbar-mobile__menu--hidden {
  transition: 0ms 300ms visibility, opacity 0.3s, top 0.3s, box-shadow 0.3s;
  visibility: hidden;
  opacity: 0;
  top: 80px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.navbar-mobile__menu-link {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  color: #24272d;
  text-decoration: none;
  width: 100%;
  min-height: 60px;
  border-bottom: 2px solid #6c6a5e;
  cursor: pointer;
}

.navbar-mobile__menu-link:last-child {
  border-bottom: 0px solid #6c6a5e;
}

.navbar-mobile__menu-link--border-top {
  border-top: 2px solid #6c6a5e;
}

.navbar-mobile__menu-link-label {
  user-select: none;
  pointer-events: none;
}

.navbar-mobile__dropdown {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 243px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25) inset;
  flex: 1 0 auto;
  overflow: hidden;
  transition: 300ms height;
}

.navbar-mobile__dropdown--hidden {
  height: 0px;
}

.navbar-mobile__dropdown-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #24272d;
  text-decoration: none;
  width: 100%;
  min-height: 60px;
  border-bottom: 1px solid #6c6a5e;
  cursor: pointer;
}

.navbar-mobile__dropdown-link-label {
  user-select: none;
  pointer-events: none;
  text-align: center;
}

.navbar-mobile__arrow-down {
  width: 12px;
  height: 12px;
  fill: #24272d;
  transition: 300ms rotate;
}

.navbar-mobile__arrow-down--up-left {
  rotate: 180deg;
}

.navbar-mobile__arrow-down--up-right {
  rotate: -180deg;
}

@media only screen and (max-width: 1280px) {
  .navbar-mobile {
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  .navbar-mobile {
    padding: 0px 10px;
  }
}
@media only screen and (max-width: 500px) {
  .navbar-mobile {
    margin-left: 10px;
    margin-right: 10px;
  }
  .navbar-mobile--fixed {
    margin: 0;
    width: calc(100% - 20px);
  }
  .navbar-mobile__logo-group {
    width: 150px;
    align-items: flex-start;
  }
  .navbar-mobile__title {
    font-size: 14px;
    text-align: center;
  }
  .navbar-mobile__hamburger {
    width: 50px;
    height: 50px;
  }
}
.doctor-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 500px;
  border: 2px solid #ffcd00;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  z-index: 1;
}

.doctor-info__title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 40px;
  width: 100%;
  padding: 10px 20px;
  background-color: #ffcd00;
  box-sizing: border-box;
}

.doctor-info__title-info-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 5px;
  font-size: 18px;
}

.doctor-info__name {
  font-weight: 700;
}

.doctor-info__major {
  font-weight: 400;
  word-break: break-word;
}

.doctor-info__icon {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  fill: #24272d;
}

.doctor-info__content-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 20px;
  background-color: #fff;
}

.doctor-info__content {
  margin: 0;
  text-align: justify;
  line-height: 24px;
}

@media only screen and (max-width: 500px) {
  .doctor-info__title-info-box {
    font-size: 16px;
  }
  .doctor-info__content-box {
    font-size: 14px;
  }
}
.medium-image {
  width: 100%;
  max-width: 500px;
  max-height: 700px;
  min-width: 0px;
  min-height: 0px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.info-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 600px;
  border: 2px solid #ffcd00;
  border-radius: 10px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  z-index: 2;
  overflow: hidden;
}

.info-box__title-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  padding: 5px 5px;
  box-sizing: border-box;
  background-color: #ffcd00;
}

.info-box__icon {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  fill: #24272d;
}

.info-box__title {
  font-size: 18px;
  font-weight: 700;
}

.info-box__content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  background-color: #fff;
  padding: 20px;
}

.info-box__content {
  margin: 0;
}

@media only screen and (max-width: 500px) {
  .info-box__title {
    font-size: 16px;
  }
  .info-box__content {
    font-size: 14px;
  }
}
.price-table {
  width: 100%;
  max-width: 1000px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  border-spacing: 0;
  overflow: hidden;
}

.price-table__header {
  width: 100%;
  font-size: 20px;
  background-color: #ffcd00;
}

.price-table__row {
  width: 100%;
  background-color: #fff;
}

.price-table__row--accent {
  background-color: #fff5cc;
}

.price-table__cell {
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  padding: 20px;
}

.price-table__cell--price {
  word-break: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  hyphens: none;
  font-weight: 700;
}

.price-table__cell--category {
  word-break: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  hyphens: none;
  text-align: center !important;
  font-weight: 700;
  text-transform: uppercase;
}

.price-table__cell:first-child {
  text-align: left;
}

.price-table__cell:last-child {
  text-align: right;
}

@media only screen and (max-width: 500px) {
  .price-table__header {
    font-size: 16px;
  }
  .price-table__cell {
    font-size: 14px;
  }
}
.dropdown-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 20px;
  width: 100%;
}

.dropdown-box__selector {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  height: 80px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  cursor: pointer;
}

.dropdown-box__selector-label-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
}

.dropdown-box__selector-label {
  font-weight: 700;
  text-align: center;
  user-select: none;
  pointer-events: none;
}

.dropdown-box__selector-icon-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  background-color: #ffcd00;
  width: 70px;
  height: 100%;
  border-radius: 10px;
}

.dropdown-box__arrow-up {
  position: relative;
  top: 0;
  max-width: 15px;
  max-height: 15px;
  fill: #24272d;
  rotate: 180deg;
  transition: 200ms top;
}

.dropdown-box__arrow-down {
  position: relative;
  top: 0;
  max-width: 15px;
  max-height: 15px;
  fill: #24272d;
  transition: 200ms top;
}

.dropdown-box__options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 500px;
  height: 0px;
  background-color: #fff;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  visibility: hidden;
  transition: 0ms 300ms visibility, 300ms 0ms height;
}

.dropdown-box__options--active {
  transition: 0ms 0ms visibility, 300ms 0ms height;
  visibility: visible;
  height: 320px;
}

.dropdown-box__option {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 80px;
  border-bottom: 2px solid #24272d;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
}

.dropdown-box__option:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 100%;
  background-color: #ffcd00;
  transition: 300ms width;
  z-index: 0;
}

.dropdown-box__option:last-child {
  border-bottom: none;
}

.dropdown-box__option-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  height: 100%;
  z-index: 1;
}

.dropdown-box__option-icon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
}

.dropdown-box__option-label-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 20px;
  z-index: 1;
}

.dropdown-box__option-label {
  user-select: none;
  pointer-events: none;
  word-break: break-word;
  color: #24272d;
}

.dropdown-box__selector:hover .dropdown-box__arrow-up {
  top: -5px;
}

.dropdown-box__selector:hover .dropdown-box__arrow-down {
  top: 5px;
}

.dropdown-box__option:hover:before {
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .dropdown-box__selector-label {
    font-size: 14px;
  }
  .dropdown-box__option-label {
    font-size: 14px;
  }
}
.captioned-image {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 400px;
  height: 600px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.captioned-image--narrow {
  width: 280px;
}

.captioned-image__image {
  max-width: 100%;
  border-radius: 10px;
}

.captioned-image__caption-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
  padding: 20px;
}

.captioned-image__title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.captioned-image__caption {
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .captioned-image {
    height: fit-content;
  }
  .captioned-image__title {
    font-size: 16px;
  }
  .captioned-image__caption {
    font-size: 14px;
  }
}
.video-youtube {
  width: 100%;
  max-width: 500px;
  height: 280px;
  border: none;
  border-radius: 10px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 500px) {
  .video-youtube {
    height: 200px;
  }
}
.document {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 50px 0px;
}

.document__title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 50px;
}

.document__content {
  width: 70%;
}

.document__buttons-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.document a {
  color: #24272d;
  text-decoration: underline;
  transition: color 300ms;
}

.document a:hover {
  color: #ffcd00;
}

.document table {
  max-width: 1000px;
  border-collapse: collapse;
  border: 2px solid #24272d;
}

.document th, .document td {
  padding: 15px;
  border: 2px solid #24272d;
}

@media only screen and (max-width: 1920px) {
  .document__content {
    width: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .document__content {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .document__title {
    font-size: 24px;
  }
  .document__content {
    font-size: 14px;
  }
  table {
    font-size: 10px;
  }
  th, td {
    padding: 5px;
  }
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 200px;
  row-gap: 100px;
  flex-wrap: wrap;
  width: 100%;
  color: #fff;
  background-color: #24272d;
  padding: 50px 20px;
  box-sizing: border-box;
  margin-top: auto;
}

.footer__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  flex-wrap: wrap;
}

.footer__section--align-left {
  align-items: flex-start;
}

.footer__section--bigger-gap {
  row-gap: 40px;
}

.footer__section--row {
  flex-direction: row;
  column-gap: 100px;
  row-gap: 50px;
  align-items: flex-start;
}

.footer__logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  margin: 0;
}

.footer__logo {
  max-width: 250px;
}

.footer__logo-caption {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.footer__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.footer__icon-link {
  width: 30px;
  height: 30px;
}

.footer__icon {
  width: 30px;
  height: 30px;
  fill: #fff;
  transition: fill 0.3s;
}

.footer__detail-box {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.footer__detail-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  fill: #fff;
}

.footer__detail-icon--bigger {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
}

.footer__detail-label-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
}

.footer__detail-label {
  user-select: all;
}

.footer__detail-label--title {
  font-weight: 700;
  user-select: none;
}

.footer__detail-link {
  color: #fff;
  text-decoration: none;
  word-break: break-all;
}

.footer__links-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.footer__links-title {
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 5px;
}

.footer__navigation-link {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s;
}

.footer__navigation-link--bold {
  font-weight: 700;
}

.footer__author {
  font-family: "Touche";
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.footer__author-link {
  font-family: "Touche";
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  text-decoration: none;
  transition: color 300ms;
}

.footer__icon:hover {
  fill: #ffcd00;
}

.footer__navigation-link:hover {
  color: #ffcd00;
}

.footer__author-link:hover {
  color: #ffcd00;
}

@media only screen and (max-width: 768px) {
  .footer__detail-label, .footer__detail-link, .footer__links-title {
    font-size: 14px;
  }
  .footer__navigation-link {
    font-size: 12px;
  }
}
.sliding-button {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  width: min-content;
  height: 50px;
  padding-right: 25px;
  color: #24272d;
  text-decoration: none;
  cursor: pointer;
}

.sliding-button__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  flex: 1 0 auto;
  z-index: 1;
}

.sliding-button__arrow {
  width: 18px;
  height: 18px;
  fill: #24272d;
}

.sliding-button__label {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  pointer-events: none;
  user-select: none;
  z-index: 1;
}

.sliding-button__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 1000px;
  background-color: #ffcd00;
  transition: width 0.3s;
}

.sliding-button:hover .sliding-button__background {
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .sliding-button {
    height: 40px;
    padding-right: 20px;
  }
  .sliding-button__label {
    font-size: 12px;
  }
  .sliding-button__arrow {
    width: 16px;
    height: 16px;
  }
  .sliding-button__icon-container, .sliding-button__background {
    width: 40px;
    height: 40px;
  }
}
.regular-button {
  position: relative;
  top: 0;
  width: 200px;
  height: 45px;
  background-color: #ffcd00;
  border-radius: 5px;
  box-sizing: border-box;
  border: none;
  box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.15);
  font-family: "Touche";
  color: #24272d;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  transition: box-shadow 300ms, top 150ms;
}

.regular-button:hover {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  top: 2px;
}

.regular-button--transparent {
  background-color: transparent;
  border: 2px solid #ffcd00;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  transition: background-color 300ms;
  font-weight: 400;
}

.regular-button--transparent:hover {
  background-color: #ffcd00;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  top: 0;
}

.sliding-select {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  height: 80px;
  background-color: #fff;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #24272d;
  text-decoration: none;
  overflow: hidden;
  z-index: 1;
}

.sliding-select:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  background-color: #ffcd00;
  border-radius: 10px;
  transition: 300ms width;
}

.sliding-select__icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20%;
  height: 100%;
  z-index: 1;
}

.sliding-select__icon {
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
}

.sliding-select__label-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 0;
  height: 100%;
  padding-left: 20px;
  padding-right: 10px;
  z-index: 1;
}

.sliding-select__label {
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sliding-select__arrow-box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  height: 100%;
  z-index: 1;
}

.sliding-select__arrow {
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  fill: #ffcd00;
  transition: 300ms 200ms fill;
}

.sliding-select:hover:after {
  width: 100%;
}

.sliding-select:hover .sliding-select__arrow {
  fill: #24272d;
}

@media only screen and (max-width: 500px) {
  .sliding-select__icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
  }
  .sliding-select__label {
    font-size: 16px;
  }
  .sliding-select__arrow-box {
    min-width: 40px;
  }
  .sliding-select__arrow {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
  }
}
.send-button {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  min-width: 200px;
  height: 50px;
  min-height: 50px;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25) inset;
  padding: 0;
  cursor: pointer;
  margin: 0 auto;
}

.send-button__icon-box {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-width: 50px;
  height: 100%;
  background-color: #ffcd00;
  z-index: 1;
  transition: 300ms width;
}

.send-button__icon {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  fill: #24272d;
}

.send-button__icon-box-fill {
  width: 50px;
  min-width: 50px;
  height: 100%;
}

.send-button__label-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.send-button__label {
  font-family: "Touche";
  font-size: 16px;
  color: #24272d;
  font-weight: 700;
  text-align: center;
}

.send-button:hover .send-button__icon-box {
  width: 100%;
}

@media only screen and (max-width: 1280px) {
  .send-button__label {
    font-size: 14px;
  }
}
.login-button {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  min-width: 200px;
  height: 50px;
  min-height: 50px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25) inset;
  padding: 0;
  cursor: pointer;
  margin: 0 auto;
}

.login-button__icon-box {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-width: 50px;
  height: 100%;
  background-color: #ffcd00;
  z-index: 1;
  transition: 300ms width;
}

.login-button__icon {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  fill: #24272d;
  rotate: 135deg;
  transition: rotate 300ms;
}

.login-button__icon-box-fill {
  width: 50px;
  min-width: 50px;
  height: 100%;
}

.login-button__label-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-button__label {
  font-family: "Touche";
  font-size: 16px;
  color: #24272d;
  font-weight: 700;
  text-align: center;
}

.login-button:hover .login-button__icon-box {
  width: 100%;
}

.login-button:hover .login-button__icon {
  rotate: 45deg;
}

@media only screen and (max-width: 1280px) {
  .login-button__label {
    font-size: 14px;
  }
}
.image-carousel {
  position: relative;
  width: 1000px;
  height: 700px;
  margin-bottom: 55px;
}

.image-carousel__controls {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 340px;
  z-index: 990;
}

.image-carousel__arrow-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-color: #24272d;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.image-carousel__arrow {
  position: relative;
  left: 0;
  right: 0;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  fill: #fff;
  transition: 300ms left;
}

.image-carousel__arrow--right {
  rotate: 0deg;
}

.image-carousel__arrow--left {
  rotate: 180deg;
}

.image-carousel__item {
  position: absolute;
  top: 0;
  left: auto;
  right: 0px;
  width: 400px;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.25);
  transform: scale(0.9);
  overflow: hidden;
  user-select: none;
  transition: 300ms right, 300ms transform;
  z-index: 1;
}

.image-carousel__item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 300ms background-color;
}

.image-carousel__item--left {
  right: 600px;
}

.image-carousel__item--center {
  right: 300px;
  transform: scale(1);
}

.image-carousel__item--center:after {
  background-color: rgba(0, 0, 0, 0);
}

.image-carousel__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-carousel__dots {
  position: absolute;
  left: 0;
  top: calc(100% + 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  width: 100%;
}

.image-carousel__dot {
  position: relative;
  width: 15px;
  height: 15px;
  background-color: #24272d;
  border-radius: 100px;
  overflow: hidden;
  transition: 300ms width;
}

.image-carousel__dot:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #6c6a5e;
}

.image-carousel__dot--active {
  width: 30px;
}

@keyframes dotProgress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.image-carousel__dot--active:after {
  animation: dotProgress 10000ms linear;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.image-carousel__arrow-box:hover .image-carousel__arrow--left {
  left: -5px;
}

.image-carousel__arrow-box:hover .image-carousel__arrow--right {
  left: 5px;
}

@media only screen and (max-width: 1280px) {
  .image-carousel {
    width: 600px;
    height: 400px;
  }
  .image-carousel__item {
    width: 250px;
  }
  .image-carousel__item--left {
    right: 350px;
  }
  .image-carousel__item--center {
    right: 175px;
  }
  .image-carousel__controls {
    column-gap: 190px;
  }
}
@media only screen and (max-width: 768px) {
  .image-carousel {
    width: 400px;
    height: 300px;
  }
  .image-carousel__item {
    width: 200px;
  }
  .image-carousel__item--left {
    right: 200px;
  }
  .image-carousel__item--center {
    right: 100px;
  }
  .image-carousel__controls {
    column-gap: 160px;
  }
  .image-carousel__arrow-box {
    width: 40px;
    height: 40px;
  }
  .image-carousel__arrow {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
  }
  .image-carousel__arrow-box:hover .image-carousel__arrow--left {
    left: 0px;
  }
  .image-carousel__arrow-box:hover .image-carousel__arrow--right {
    left: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .image-carousel {
    width: 300px;
    height: 250px;
  }
  .image-carousel__item {
    width: 150px;
  }
  .image-carousel__item--left {
    right: 150px;
  }
  .image-carousel__item--center {
    right: 75px;
  }
  .image-carousel__controls {
    column-gap: 110px;
  }
}
.contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
}

.contact-form__title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.contact-form__title--left {
  text-align: left;
}

.contact-form__details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 20px;
  background: linear-gradient(135deg, rgb(255, 205, 0) 0%, rgb(255, 229, 0) 100%);
  border-radius: 10px;
  width: 40%;
  height: 100%;
  padding: 40px 10px;
  box-sizing: border-box;
}

.contact-form__detail-boxes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 50px;
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
}

.contact-form__detail-box {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
}

.contact-form__detail-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border: 2px solid #24272d;
  border-radius: 100px;
  padding: 20px;
  box-sizing: border-box;
}

.contact-form__detail-icon {
  width: 100%;
  height: 100%;
  fill: #24272d;
}

.contact-form__detail-label-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 10px;
}

.contact-form__detail-label {
  font-size: 18px;
  color: #24272d;
  word-break: break-word;
}

.contact-form__detail-label--title {
  font-size: 22px;
  font-weight: 700;
}

.contact-form__form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 30px;
  width: 60%;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.contact-form__form-title-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 20px;
}

.contact-form__subtitle {
  margin: 0;
  font-size: 18px;
}

.contact-form__checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
}

.contact-form__checkbox-label {
  position: relative;
  top: 1px;
  font-size: 12px;
  color: #24272d;
}

.contact-form__checkbox-link {
  color: #24272d;
  text-decoration: underline;
  transition: color 300ms;
}

.contact-form__checkbox-link:hover {
  color: #ffcd00;
}

.contact-form__field-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 5px;
  width: 100%;
  max-width: 100%;
  margin: 0px 0px;
}

.contact-form__field-wrapper--expand {
  height: 100%;
}

.contact-form__fields-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 30px;
  width: 100%;
}

.contact-form__error-label {
  font-size: 12px;
  color: #ff6961;
}

@media only screen and (max-width: 1280px) {
  .contact-form {
    height: 620px;
  }
  .contact-form__detail-icon-box {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    padding: 15px;
  }
  .contact-form__detail-label {
    font-size: 16px;
  }
  .contact-form__detail-label--title {
    font-size: 18px;
  }
  .contact-form__subtitle {
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .contact-form {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    row-gap: 50px;
    height: fit-content;
    background-color: transparent;
    box-shadow: none;
  }
  .contact-form__details {
    width: 100%;
    box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  }
  .contact-form__detail-boxes {
    width: fit-content;
  }
  .contact-form__form {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  }
}
@media only screen and (max-width: 500px) {
  .contact-form__title {
    font-size: 24px;
  }
  .contact-form__form {
    row-gap: 20px;
    height: 750px;
  }
  .contact-form__fields-row {
    flex-direction: column;
    row-gap: 20px;
  }
}
.form-field {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
}

.form-field__label {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 80%;
  height: 20px;
  padding: 5px 10px;
  background-color: #fff;
  color: #6c6a5e;
  white-space: nowrap;
  text-align: center;
  user-select: none;
  pointer-events: none;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 150ms top, 150ms color, 150ms font-size;
}

.form-field__input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 2px solid #6c6a5e;
  border-radius: 5px;
  padding: 0px 20px;
  font-family: "Touche";
  color: #24272d;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
  resize: none;
  transition: 300ms border;
}

.form-field__input--error {
  border: 2px solid #ff6961 !important;
}

.form-field__textarea {
  font-family: "Touche";
  color: #24272d;
  font-size: 16px;
  padding: 20px 20px;
}

.form-field__input::placeholder {
  opacity: 0;
}

.form-field__input:focus {
  border: 2px solid #ffcd00;
}

.form-field__input:focus + .form-field__label {
  top: -14px;
  color: #ffcd00;
  font-size: 14px;
}

.form-field__input:not(:placeholder-shown) {
  border: 2px solid #ffcd00;
}

.form-field__input:not(:placeholder-shown) + .form-field__label {
  top: -14px;
  font-size: 14px;
}

@media only screen and (max-width: 1280px) {
  .form-field__input {
    font-size: 14px;
  }
  .form-field__label {
    font-size: 14px;
  }
  .form-field__input:focus + .form-field__label {
    font-size: 12px;
  }
  .form-field__input:not(:placeholder-shown) + .form-field__label {
    font-size: 12px;
  }
}
.form-field-regular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 10px;
  width: 100%;
}

.form-field-regular__label {
  font-size: 16px;
  font-weight: 700;
  color: #24272d;
  text-align: center;
}

.form-field-regular__error {
  font-size: 12px;
  color: #ff6961;
}

.form-field-regular__input-wrapper {
  position: relative;
  width: 100%;
  height: 45px;
}

.form-field-regular__password-button {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  opacity: 0.4;
  cursor: pointer;
}

.form-field-regular__icon {
  width: 100%;
  height: 100%;
  fill: #6c6a5e;
}

.form-field-regular__input {
  width: 100%;
  height: 45px;
  background-color: transparent;
  border: 2px solid #6c6a5e;
  border-radius: 5px;
  padding: 0px 10px;
  font-family: "Touche";
  color: #24272d;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
  resize: none;
  transition: 300ms border;
}

.form-field-regular__input--error {
  border: 2px solid #ff6961;
}

.form-field-regular__input:focus {
  border: 2px solid #ffcd00;
}

.form-field-regular__input:not(:placeholder-shown) {
  border: 2px solid #ffcd00;
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin: 0;
  background-color: transparent;
  border: 2px solid #6c6a5e;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border 300ms;
}

.checkbox:hover {
  border: 2px solid #ffcd00;
}

.checkbox:checked {
  border: 2px solid #ffcd00;
}

.checkbox--inactive {
  cursor: not-allowed;
}

.checkbox:before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #ffcd00;
  border-radius: 2px;
  transform: scale(0);
  transition: transform 100ms;
}

.checkbox:checked:before {
  transform: scale(1);
}

.team-member {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 10px;
  width: 300px;
  height: 300px;
  background-color: #fff;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px 10px;
  padding-top: 100px;
  box-sizing: border-box;
}

.team-member__image {
  position: absolute;
  top: -75px;
  width: 150px;
  height: 150px;
  border-radius: 1000px;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.2);
}

.team-member__name {
  font-weight: 700;
  text-align: center;
}

.team-member__major {
  text-align: center;
  flex: 1 0 auto;
}

.team-member__separator {
  width: 80%;
  height: 2px;
  border-radius: 100px;
  background-color: #24272d;
  margin-bottom: 10px;
}

.review {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 50px;
  width: 500px;
}

.review__cloud {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  max-height: 300px;
  background-color: #fff;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 45px 80px;
  box-sizing: border-box;
}

.review__cloud:after {
  content: "";
  position: absolute;
  bottom: -40px;
  left: 100px;
  width: 40px;
  height: 40px;
  border-top: 20px solid #fff;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #fff;
  border-right: 20px solid transparent;
  box-sizing: border-box;
}

.review__content {
  word-break: break-word;
  overflow-y: scroll;
  margin: 0;
}

.review__quote-box {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review__quote-box--top {
  left: auto;
  bottom: auto;
  top: 10px;
  right: 10px;
}

.review__quote {
  width: 100%;
  height: 100%;
  fill: #24272d;
  rotate: 180deg;
}

.review__stars-box {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}

.review__star {
  width: 25px;
  height: 25px;
  max-width: 25px;
  max-height: 25px;
  fill: #24272d;
}

.review__star--active {
  fill: #ffe500;
}

.review__user {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  margin-left: 25px;
}

.review__user-picture-box {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border: 3px solid #24272d;
  border-radius: 1000px;
  overflow: hidden;
}

.review__user-picture {
  width: 100%;
  height: 100%;
}

.review__user-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 5px;
}

.review__user-name {
  font-size: 18px;
  font-weight: 700;
}

@media only screen and (max-width: 500px) {
  .review {
    width: 300px;
  }
  .review__cloud {
    padding: 45px 40px;
  }
  .review__content {
    font-size: 14px;
  }
  .review__user {
    margin-left: 0px;
  }
  .review__user-picture-box {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
  }
}
.google-reviews {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  height: 500px;
  overflow: hidden;
}

.google-reviews__fade-box {
  width: 200px;
  height: 100%;
  background: linear-gradient(90deg, #ebeae6 0%, rgba(235, 234, 230, 0) 100%);
  z-index: 1;
}

.google-reviews__fade-box--left {
  background: linear-gradient(90deg, rgba(235, 234, 230, 0) 0%, #ebeae6 100%);
}

.google-reviews__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 5500px;
  height: 100%;
}

.google-reviews__box {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  column-gap: 50px;
  padding: 20px 40px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  animation: primary 60s linear infinite;
}

.google-reviews__box--secondary {
  animation: secondary 60s linear infinite;
}

.google-reviews__wrapper:hover .google-reviews__box {
  animation-play-state: paused;
}

@keyframes primary {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes secondary {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@media only screen and (max-width: 500px) {
  .google-reviews__fade-box {
    display: none;
  }
  .google-reviews__wrapper {
    width: 3500px;
  }
}
.notification-box {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  row-gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  user-select: none;
  pointer-events: none;
  z-index: 995;
}

.notification {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  min-height: 100px;
  background-color: #50c878;
  border-radius: 10px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  padding: 10px;
  pointer-events: all;
  box-sizing: border-box;
  overflow: hidden;
}

.notification--error {
  background-color: #ff6961;
}

.notification__icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
}

.notification__icon {
  width: 100%;
  height: 100%;
  fill: #24272d;
}

.notification__message {
  font-size: 14px;
  margin: 0;
}

.notification__timebar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #24272d;
  animation: animateTimebar linear 10s;
  animation-fill-mode: forwards;
}

@keyframes animateTimebar {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
.cookie-banner__wrapper {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 100%;
  min-height: 0;
  padding: 20px;
  box-sizing: border-box;
  z-index: 999;
}

.cookie-banner {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  max-width: 1000px;
  min-height: 0;
  padding: 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  overflow: scroll;
}

.cookie-banner__title {
  font-size: 20px;
  font-weight: 700;
}

.cookie-banner__title--expanded {
  text-align: center;
}

.cookie-banner__info {
  margin: 0;
}

.cookie-banner__info--expanded {
  display: none;
}

.cookie-banner__button-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 50px;
  row-gap: 20px;
  flex-wrap: wrap;
  margin-top: 25px;
}

.cookie-banner__options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 25px 0px;
}

.cookie-banner__options-separator {
  width: 0px;
  height: 100px;
  border: none;
  border-left: 2px dashed rgba(108, 106, 94, 0.25);
  margin: 0;
}

.cookie-banner__option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  width: 100%;
  max-width: 250px;
}

.cookie-banner__option-title-layout {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.cookie-banner__option-title {
  font-size: 14px;
  font-weight: 700;
}

.cookie-banner__option-info {
  margin: 0;
  font-size: 12px;
}

.cookie-banner__link {
  color: #24272d;
  text-decoration: underline;
  transition: color 300ms;
}

.cookie-banner__link:hover {
  color: #ffcd00;
}

@media only screen and (max-width: 768px) {
  .cookie-banner__options {
    flex-direction: column;
  }
  .cookie-banner__options-separator {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .cookie-banner__wrapper {
    padding: 10px;
  }
  .cookie-banner__title {
    font-size: 16px;
  }
  .cookie-banner__info {
    font-size: 14px;
  }
}
.admin-login__wrapper {
  position: relative;
  max-width: 500px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  box-sizing: border-box;
}

.admin-login {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  width: 100%;
  padding: 30px 80px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  z-index: 10;
}

.admin-login__decoration {
  position: absolute;
  top: -30px;
  left: -30px;
  width: 100px;
  height: 100px;
  background-color: #ffcd00;
  border-radius: 9999px;
  z-index: 1;
}

.admin-login__decoration--medium {
  left: auto;
  top: auto;
  right: -25px;
  bottom: -50px;
  width: 150px;
  height: 150px;
}

.admin-login__title {
  font-family: "Touche";
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.admin-login__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  width: 100%;
}

.admin-login__credentials-error {
  font-family: "Touche";
  color: #ff6961;
  font-size: 14px;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .admin-login {
    padding: 30px 30px;
  }
}
.admin-base-container {
  padding-top: 140px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.admin-base-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 0px 40px;
  box-sizing: border-box;
}

.admin-base-page__editor {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.admin-base-page__title {
  font-family: "Touche";
  color: #24272d;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
}

.admin-base-page__form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 30px;
  margin: 50px 0px;
  width: 100%;
}

.admin-base-page__textarea {
  font-family: "Touche";
  color: #24272d;
  font-size: 16px;
  width: 100%;
  min-height: 200px;
  max-height: 400px;
  padding: 10px;
  resize: vertical;
  outline: none;
  background-color: #fff;
  border: 2px solid #6c6a5e;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}

.admin-base-page__status {
  font-family: "Touche";
  color: #24272d;
  font-size: 14px;
  text-align: center;
}

.admin-base-page__status--error {
  color: #ff6961;
}

.admin-base-page__status--success {
  color: #50c878;
}

.admin-base-page__separator {
  width: 100%;
  height: 0;
  border: none;
  border-top: 2px dashed #6c6a5e;
  margin: 50px 0px;
}

.admin-base-page__selection-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  width: 100%;
  margin: 50px 0px;
}

.admin-base-page__selection-link {
  width: 100%;
  max-width: 600px;
  padding: 20px 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Touche";
  color: #24272d;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  word-wrap: break-word;
  transition: background-color 300ms, box-shadow 300ms;
}

.admin-base-page__selection-link:hover {
  background-color: #ffcd00;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 1280px) {
  .admin-base-container {
    padding-top: 160px;
  }
}
@media only screen and (max-width: 500px) {
  .admin-base-container {
    padding-top: 250px;
  }
}
.admin-top-bar {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  padding: 20px 50px;
  background-color: #fff;
  box-shadow: 5px 5px 20px 1px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  z-index: 999;
}

.admin-top-bar__left-container, .admin-top-bar__right-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.admin-top-bar__subcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.admin-top-bar__title {
  font-family: "Touche";
  color: #24272d;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
}

.admin-top-bar__info {
  font-family: "Touche";
  color: #24272d;
  font-size: 16px;
  text-align: center;
}

.admin-top-bar__number {
  font-family: "Roboto";
  font-weight: 700;
}

.admin-top-bar__separator {
  width: 0;
  height: 40px;
  border: none;
  border-left: 2px solid #ffcd00;
}

.admin-top-bar__button {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  cursor: pointer;
}

.admin-top-bar__icon {
  width: 100%;
  height: 100%;
  fill: #24272d;
  transition: fill 200ms;
}

.admin-top-bar__button:hover .admin-top-bar__icon {
  fill: #ffcd00;
}

@media only screen and (max-width: 1280px) {
  .admin-top-bar {
    flex-direction: column;
    justify-content: center;
  }
  .admin-top-bar__title {
    font-size: 20px;
  }
  .admin-top-bar__info {
    font-size: 14px;
  }
  .admin-top-bar__button {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }
  .admin-top-bar__separator {
    height: 30px;
  }
}
@media only screen and (max-width: 500px) {
  .admin-top-bar__right-container {
    width: 100%;
    flex-direction: column;
  }
  .admin-top-bar__separator--mobile-hidden {
    display: none;
  }
  .admin-top-bar__info {
    width: 100%;
    max-width: 200px;
  }
}
.admin-nav-bar {
  position: fixed;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 20px;
  padding-right: 5px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  z-index: 999;
}

.admin-nav-bar__items-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.admin-nav-bar__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.admin-nav-bar__item:hover .admin-nav-bar__dot {
  background-color: #ffcd00;
}

.admin-nav-bar__dot {
  width: 12px;
  height: 12px;
  border-radius: 999px;
  background-color: #6c6a5e;
  transition: background-color 200ms;
}

.admin-nav-bar__dot--active {
  background-color: #ffcd00;
}

.admin-nav-bar__link {
  font-family: "Touche";
  color: #24272d;
  font-size: 16px;
  text-decoration: none;
}

.admin-nav-bar__number {
  color: #ffcd00;
  font-weight: 700;
}

.admin-nav-bar__number--zero {
  color: #6c6a5e;
  opacity: 0.5;
}

.admin-nav-bar__hide-button {
  font-family: "Touche";
  color: #24272d;
  font-size: 14px;
  font-weight: 700;
  rotate: -90deg;
  cursor: pointer;
}

.admin-nav-bar__show-button {
  display: initial;
  position: fixed;
  top: 50%;
  left: 0;
  font-family: "Touche";
  color: #24272d;
  font-size: 14px;
  font-weight: 700;
  transform-origin: top left;
  transform: rotate(-90deg) translateX(-50%);
  background-color: #fff;
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  cursor: pointer;
  z-index: 999;
}

.admin-price-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 20px;
  width: 100%;
  margin: 50px 0px;
}

.admin-price-table__row-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  width: 100%;
}

.admin-price-table__header, .admin-price-table__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  background-color: #ffcd00;
  border-radius: 10px;
  width: 100%;
  height: 60px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.admin-price-table__row {
  background-color: #fff;
}

.admin-price-table__label {
  font-family: "Touche";
  color: #24272d;
  font-size: 16px;
  text-align: center;
}

.admin-price-table__label--header {
  font-weight: 700;
}

.admin-price-table__input {
  width: 100%;
  height: 100%;
  padding: 5px;
  border: none;
  box-sizing: border-box;
  outline: none;
  font-family: "Touche";
  color: #24272d;
  font-size: 14px;
}

.admin-price-table__input--right {
  width: 150px;
  border-left: 2px solid #6c6a5e;
  text-align: right;
}

.admin-price-table__input--category {
  width: 100%;
  border: none;
  text-align: center;
}

.admin-price-table__button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.admin-price-table__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  background-color: #6c6a5e;
  border-radius: 5px;
  cursor: pointer;
}

.admin-price-table__button--delete {
  background-color: #ff6961;
}

.admin-price-table__button--active {
  background-color: #ffcd00;
}

.admin-price-table__button--narrow {
  width: 25px;
  min-width: 25px;
  cursor: ns-resize;
  transition: background-color 100ms;
}

.admin-price-table__button--narrow:active {
  background-color: #ffcd00;
}

.admin-price-table__icon {
  width: 50%;
  height: 50%;
  fill: #24272d;
}

.admin-price-table__icon--normal {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
}

.admin-price-table__icon--full {
  width: 80%;
}

.admin-price-table__add-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border: 2px dashed #6c6a5e;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 50px;
}

.admin-price-table__reorder-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 20px;
  width: 100%;
  padding: 0;
  margin: 0;
}

.admin-price-table__notification {
  font-family: "Touche";
  color: #6c6a5e;
  font-size: 14px;
  text-align: center;
}

.admin-price-table__notification--error {
  color: #ff6961;
}

.admin-price-table__notification--success {
  color: #50c878;
}

@media only screen and (max-width: 768px) {
  .admin-price-table__row-wrapper {
    column-gap: 5px;
  }
  .admin-price-table__header, .admin-price-table__row {
    height: 50px;
  }
  .admin-price-table__row {
    padding: 0px 5px;
  }
  .admin-price-table__input {
    font-size: 12px;
  }
  .admin-price-table__input--right {
    width: 70px;
  }
  .admin-price-table__button-group {
    flex-direction: column;
    gap: 2px;
  }
  .admin-price-table__button {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
  }
  .admin-price-table__button--narrow {
    height: 50px;
    min-height: 50px;
  }
  .admin-price-table__add-row {
    height: 50px;
  }
}
.admin-client-message__main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 50px;
  width: 100%;
  margin-top: 50px;
}

.admin-client-message__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  width: 100%;
}

.admin-client-message__wrapper:hover .admin-client-message__big-number {
  color: #ffcd00;
}

.admin-client-message__side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.admin-client-message__big-number {
  font-family: "Roboto";
  color: #24272d;
  font-size: 64px;
  text-align: center;
  font-weight: 900;
  transition: color 300ms;
}

.admin-client-message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 30px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;
}

.admin-client-message__info-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  justify-content: center;
  align-items: flex-start;
}

.admin-client-message__info-wrapper--bottom {
  width: 100%;
}

.admin-client-message__info-title {
  font-family: "Touche";
  color: #24272d;
  font-size: 16px;
  text-align: center;
  font-weight: 700;
}

.admin-client-message__info {
  font-family: "Touche";
  color: #24272d;
  font-size: 16px;
  word-break: break-word;
}

.admin-client-message__delete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  background-color: #ff6961;
  border-radius: 5px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.admin-client-message__icon {
  width: 60%;
  height: 60%;
  fill: #24272d;
}

.admin-client-message__notification {
  font-family: "Touche";
  color: #24272d;
  font-size: 16px;
  text-align: center;
}

.admin-client-message__notification--error {
  color: #ff6961;
}

@media only screen and (max-width: 500px) {
  .admin-client-message__wrapper {
    flex-direction: column;
  }
  .admin-client-message__side {
    flex-direction: row;
    column-gap: 20px;
  }
}
.admin-change-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 40px;
  margin-top: 50px;
  width: 100%;
}

.admin-change-password__title {
  font-family: "Touche";
  color: #24272d;
  font-size: 20px;
  font-weight: 700;
  text-decoration: underline;
}

.admin-change-password__notification {
  font-family: "Touche";
  color: #50c878;
  font-size: 14px;
  text-align: center;
}

.admin-change-password__notification--error {
  color: #ff6961;
}

.admin-change-password__fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  width: 100%;
  max-width: 300px;
}

.main-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main-page__section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px;
  padding: 50px 20px;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
}

.main-page__section--row {
  flex-direction: row;
}

.main-page__section--wide {
  width: 100%;
  max-width: 100%;
  padding: 50px 0px;
}

.main-page__group {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.main-page__group--row {
  flex-direction: row;
}

.main-page__group--left {
  align-items: flex-start;
  width: 100%;
}

.main-page__group--fixed {
  width: 100%;
  max-width: 1600px;
  padding: 0px 20px;
  box-sizing: border-box;
}

.main-page__group--wide {
  width: 100%;
  box-sizing: border-box;
}

.main-page__group--margin-bottom {
  margin-bottom: 100px;
}

.main-page__group--space-around {
  width: 100%;
  justify-content: space-around;
}

.main-page__group--hex {
  padding-top: 20px;
  padding-bottom: 20px;
  background-image: url("../svg/LargeHexagon.svg");
}

.main-page__group--team-members {
  column-gap: 50px;
  row-gap: 125px;
  padding: 75px 20px;
  background-position: -200px -200px;
}

.main-page__logo {
  width: 100%;
  max-width: 300px;
  min-width: 0px;
}

.main-page__title-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  width: 100%;
}

.main-page__title-group--left {
  align-items: flex-start;
}

.main-page__title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  max-width: 650px;
}

.main-page__title--normal {
  font-weight: 400;
}

.main-page__title--left {
  text-align: left;
}

.main-page__subtitle {
  font-size: 20px;
  text-align: center;
  max-width: 650px;
}

.main-page__subtitle--left {
  text-align: left;
}

.main-page__info {
  text-align: justify;
  z-index: 1;
}

.main-page__label-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 10px 20px;
}

.main-page__label-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  opacity: 0.15;
}

.main-page__image-right-edge {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
  width: 900px;
  height: 600px;
  object-fit: cover;
  border-radius: 10px 0px 0px 10px;
  z-index: 0;
}

.main-page__youtube-video {
  width: 500px;
  height: 300px;
  min-width: 0;
  min-height: 0;
  border-radius: 10px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.25);
  border: none;
  z-index: 1;
}

@media only screen and (max-width: 1920px) {
  .main-page__image-right-edge {
    width: 700px;
    height: 500px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-page__image-right-edge {
    display: none;
  }
  .main-page__group--mobile-center {
    justify-content: center;
    align-items: center;
  }
  .main-page__group--margin-bottom {
    margin-bottom: 0px;
  }
  .main-page__subtitle--mobile-center {
    text-align: center;
  }
  .main-page__label-image {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .main-page__section {
    row-gap: 30px;
    padding: 30px 5px;
  }
  .main-page__section--no-side-padding {
    padding: 30px 0px;
  }
  .main-page__group--fixed {
    padding: 0;
  }
  .main-page__group--team-members {
    padding: 75px 0px;
  }
  .main-page__title {
    font-size: 24px;
  }
  .main-page__subtitle {
    font-size: 16px;
  }
  .main-page__info {
    font-size: 14px;
  }
  .main-page__youtube-video {
    width: 350px;
    height: 200px;
  }
}
.clinic-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 50px 0px;
  box-sizing: border-box;
}

.clinic-page__section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 50px;
  width: 100%;
  padding: 50px 20px;
  box-sizing: border-box;
}

.clinic-page__section--row {
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 50px;
}

.clinic-page__section--hexagon {
  background-image: url("../svg/LargeHexagon.svg");
}

.clinic-page__row-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  width: 100%;
}

.clinic-page__title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.clinic-page__caption {
  text-align: center;
}

.clinic-page__subtitle {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.clinic-page__subtitle-caption {
  position: relative;
  top: -30px;
  text-align: center;
  max-width: 600px;
}

.clinic-page__info {
  text-align: center;
}

.clinic-page__info--small {
  font-size: 14px;
}

@media only screen and (max-width: 500px) {
  .clinic-page__section {
    row-gap: 30px;
    padding: 30px 20px;
  }
  .clinic-page__title {
    font-size: 24px;
  }
  .clinic-page__caption {
    font-size: 14px;
  }
  .clinic-page__subtitle {
    font-size: 20px;
  }
  .clinic-page__subtitle-caption {
    top: -10px;
  }
  .clinic-page__info {
    font-size: 14px;
  }
  .clinic-page__info--small {
    font-size: 12px;
  }
}
.pricing-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 0px;
}

.pricing-page__section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 50px 20px;
  box-sizing: border-box;
}

.pricing-page__title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.pricing-page__subtitle {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.pricing-page__clinic-name {
  position: relative;
  top: -40px;
  font-size: 20px;
  text-align: center;
}

.pricing-page__info {
  text-align: center;
}

.pricing-page__notes {
  max-width: 1000px;
}

@media only screen and (max-width: 500px) {
  .pricing-page__section {
    row-gap: 30px;
    padding: 30px 20px;
  }
  .pricing-page__title {
    font-size: 24px;
  }
  .pricing-page__subtitle {
    font-size: 20px;
  }
  .pricing-page__clinic-name {
    top: -20px;
    font-size: 16px;
  }
  .pricing-page__info {
    font-size: 14px;
  }
  .pricing-page__notes {
    font-size: 14px;
  }
}
.doctor-page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 100px;
  padding: 50px 20px;
  margin: 100px 0px;
  background-image: url("../svg/LargeHexagon.svg");
}

.error-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 50px;
  width: 100%;
  height: 100%;
  padding: 100px 20px;
  box-sizing: border-box;
  flex: 1 0 auto;
}

.error-page__title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.error-page__code {
  position: relative;
  top: -30px;
  font-size: 24px;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .error-page__title {
    font-size: 24px;
  }
  .error-page__code {
    font-size: 20px;
  }
}
.admin-login-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url("../svg/LargeHexagon.svg");
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 100px;
}

body {
  margin: 0;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 0px;
  min-height: 100vh;
  font-family: "Touche";
  font-size: 16px;
  color: #24272d;
  background-color: #ebeae6;
}
